import React from "react"
import Img from "gatsby-image"
import { graphql } from 'gatsby'
import {Link} from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

import CheckListPDF from "../../static/Checkliste_Homeoffice.pdf"

export const query = graphql`
    query {
        hero: file(relativePath: {eq: "hero.png"}) {
            childImageSharp {
                fluid(maxWidth: 1080) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        featureOne: file(relativePath: {eq: "madeeasy.png"}) {
            childImageSharp {
                fluid(maxWidth: 1080) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        featureTwo: file(relativePath: {eq: "psycho.png"}) {
            childImageSharp {
                fluid(maxWidth: 1080) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`

const IndexPage = ({ data }) => (
    <Layout>
        <SEO title="Homeoffice made easy"/>

        <div className="pageHero">
            <div className="container">
                <div className="row middle-sm">
                    <div className="col-xs-12 center-xs col-sm-6 start-sm">
                        <h1 className="pageHeroTitle">Die digitale Atemschutzmaske - sind Sie bereit für Homeoffice?</h1>
                        <p className="pageHeroSubTitle">Schöpfen Sie aus 14 Jahren Erfahrung!</p>
                        <p className="pageHeroTruncate">Sie stoßen auf <strong>Herausforderungen</strong> im Aufsetzen von "Home office"? Wissen nicht mehr weiter und können nicht schnell reagieren? Dann lassen Sie sich helfen, <strong>schnell und zukunftssicher</strong> auf digitale Mittel umzustellen. Wir machen Sie und Ihre Mitarbeiter fit für das digitale Universum: <br/><strong>Homeoffice ist erst der Start.</strong></p>
                        <Link to="/three-steps" className="button secondary">Jetzt Ihre Zukunft sichern</Link>
                    </div>
                    <div className="col-xs-12 col-sm-6">
                        <Img fluid={data.hero.childImageSharp.fluid} />
                    </div>
                </div>
            </div>
        </div>

        <div className="container section">
            <div className="row">
                <div className="col-xs-12 center-xs col-sm-6 start-sm">
                    <h2 className="text-secondary">Und auf einmal kam Corona.</h2>
                    <p>Sie kommen nicht ins Netzwerk, haben nicht die Hardware oder Ihre Prozesse sind noch nicht auf digital abgestimmt. Sie wissen nicht wie und alles kommt so plötzlich? Sie müssen JETZT handeln und ein Problem nach dem anderen taucht auf?</p>
                    <div className="checkList">
                        <div className="listItem bad">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 2a10 10 0 100 20 10 10 0 000-20zm5 13.6L15.6 17 12 13.4 8.4 17 7 15.6l3.6-3.6L7 8.4 8.4 7l3.6 3.6L15.6 7 17 8.4 13.4 12l3.6 3.6z"/><path d="M0 0h24v24H0z" fill="none"/></svg>
                            Prozesse nicht adaptiert
                        </div>
                        <div className="listItem bad">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 2a10 10 0 100 20 10 10 0 000-20zm5 13.6L15.6 17 12 13.4 8.4 17 7 15.6l3.6-3.6L7 8.4 8.4 7l3.6 3.6L15.6 7 17 8.4 13.4 12l3.6 3.6z"/><path d="M0 0h24v24H0z" fill="none"/></svg>
                            Arbeitsmittel und Tools fehlen
                        </div>
                        <div className="listItem bad">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 2a10 10 0 100 20 10 10 0 000-20zm5 13.6L15.6 17 12 13.4 8.4 17 7 15.6l3.6-3.6L7 8.4 8.4 7l3.6 3.6L15.6 7 17 8.4 13.4 12l3.6 3.6z"/><path d="M0 0h24v24H0z" fill="none"/></svg>
                            Zugang zu wichtigen Daten blockiert
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 center-xs col-sm-6 start-sm">
                    <h2 className="text-success">Wir unterstützen Sie!</h2>
                    <p>14 Jahre Erfahrung machen es möglich.</p>
                    <p>In kurzer Zeit Homeoffice und produktiv? Sie denken, das ist nicht möglich - ist es und das können Sie auch. Wir möchten Ihnen dabei helfen sich abzusichern. Digital vorbereitet zu sein war noch nie wichtiger als jetzt. Profitieren Sie von unseren Erfahrungen!</p>
                    <div className="checkList">
                        <div className="listItem good">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2a10 10 0 100 20 10 10 0 000-20zm-2 15l-5-5 1.4-1.4 3.6 3.6 7.6-7.6L19 8l-9 9z"/></svg>
                            Individuelle Beratung
                        </div>
                        <div className="listItem good">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2a10 10 0 100 20 10 10 0 000-20zm-2 15l-5-5 1.4-1.4 3.6 3.6 7.6-7.6L19 8l-9 9z"/></svg>
                            Aufsetzen von Homeoffice
                        </div>
                        <div className="listItem good">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2a10 10 0 100 20 10 10 0 000-20zm-2 15l-5-5 1.4-1.4 3.6 3.6 7.6-7.6L19 8l-9 9z"/></svg>
                            Hardware-Beschaffung
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="pageHero sectionHero">
            <div className="container section">
                <div className="row">
                    <div className="col-xs-12 center-xs">
                        <h2 className="pageHeroTitle">Realitätscheck: Wie gut sind Sie vorbereitet?</h2>
                        <p className="pageHeroSubTitle">Wir haben Ihnen eine 5-Minuten-Checkliste zusammengestellt, die Ihnen in nur wenigen Fragen zeigt, was Sie jetzt und sofort erreichen können.Die Checkliste gibt Ihnen einen kurzen Überblick, woran Sie denken müssen. Gerne unterstützen wir Sie dabei, Maßnahmen zu ergreifen.</p>
                        <br/><br/>
                        <a className="button secondary" href={CheckListPDF} download>Checkliste herunterladen</a>
                    </div>
                </div>
            </div>
        </div>

        <div className="bg-muted">
            <div className="container section">
                <div className="row">
                    <div className="col-xs-12 center-xs col-sm-6 start-sm">
                        <Img fluid={data.featureOne.childImageSharp.fluid} />
                    </div>
                    <div className="col-xs-12 center-xs col-sm-6 start-sm">
                        <h2 className="text-primary">Homeoffice made easy</h2>
                        <p>Jeden Tag müssen mehr Unternehmen ihre Mitarbeiterinnen und Mitarbeiter nach hause schicken. Weil eine behördliche Anordnung sie dazu zwingt oder weil sich jemand aus dem Team möglicherweise oder auch nachgewiesen mit dem neuartigen Coronavirus infiziert hat. Und nun muss das ganze Team in Quarantäne.</p>
                        <p><strong>Wer jetzt nicht vorbereitet ist, den trifft es besonders hart.</strong> Kunden vertrösten Sie oder möchten nicht investieren, Mitarbeiterinnen und Mitarbeiter sind verunsichert und die Leistungskurve sinkt.</p>
                    </div>
                </div>
            </div>
        </div>

        <div className="container section">
            <div className="row">
                <div className="col-xs-12 center-xs col-sm-6 start-sm">
                    <h2 className="text-primary">Zusammengefasst:</h2>
                    <p>Die wirtschaftliche Existenz steht auf dem Spiel und eigentlich gesunde Unternehmen sehen der Pleite ins Auge.</p>
                    <p>Oder Sie haben sich vorbereitet und schaffen es, die Krise zu meistern. Und das fängt bei den Grundlagen an. Können Sie Ihre Belegschaft morgen oder vielleicht sogar jetzt gleich ins Homeoffice schicken? Können alle dann ihre gewohnten Aufgaben erledigen? Oder haben Sie den Plan schon beerdigt, bevor Sie ihn richtig durchdacht haben? Zu aufwendig, keine passenden Laptops, keine Kollaborationsmöglichkeiten vorhanden?</p>                    
                </div>
                <div className="col-xs-12 center-xs col-sm-6 start-sm">
                    <Img fluid={data.featureTwo.childImageSharp.fluid} />
                </div>
            </div>
        </div>

        <div className="bg-muted">
            <div className="container section">
                <div className="row">
                    <div className="col-xs-12 center-xs col-sm-6 start-sm">
                        <h2 className="text-primary">Homeoffice für den Ernstfall</h2>
                        <p>Die aktuelle Pandemie ist der Ernstfall. Keine Zeit für lange Beschaffungen und umfangreiche Personalentwicklung. Es geht darum, dass die wichtigsten Prozesse weiterlaufen und dass Ihre Mitarbeiterinnen und Mitarbeiter nicht in häuslicher Quarantäne unterbeschäftigt und handlungsunfähig darauf warten, dass sich die Situation bessert.</p>
                    </div>
                    <div className="col-xs-12 center-xs col-sm-6 start-sm">
                        <p className="tipBox"><strong>Tipp:</strong> In der Psychologie ist es schon lange bekannt, dass Menschen, die sich als handlungsfähig erleben und ihre Selbstwirksamkeit wahrnehmen, besser mit Krisensituationen umgehen und diese eher bewältigen. Deswegen ist es nicht nur ein Gebot des wirtschaftlichen Überlebens, Ihr Unternehmen handlungsfähig zu erhalten. Es ist auch wichtig für Ihre Beschäftigten, jetzt aktiv am Überleben des Unternehmens und damit ihres eigenen Arbeitsplatzes aktiv mitzuwirken.</p>
                    </div>
                </div>
            </div>
        </div>

        <div className="call-to-action">
            <div className="container">
                <div className="call-to-action__content center-xs">
                    <h2 className="pageHeroTitle">Sie möchten erfahren, wie Sie jetzt Homeoffice einführen können?</h2>
                    <p>Wir ermöglichen Ihre Beschäftigten einen schnellen Einstieg in digitaler Zusammenarbeit</p>
                    <Link className="btn-endSection" to="/contact">Lassen Sie sich jetzt beraten</Link>
                </div>
            </div>
        </div>
    </Layout>
)

export default IndexPage
